import { Auth0Plugin, createAuth0 } from '@auth0/auth0-vue'

import { DeployConfig } from '~/composables/useAppConfig'

let auth0Plugin: Auth0Plugin

const useAuth = (appConfig: DeployConfig): Auth0Plugin => {
  const isPassless = localStorage.getItem('passless')

  if (!auth0Plugin) {
    auth0Plugin = createAuth0({
      domain: appConfig.AUTH0_DOMAIN,
      clientId: isPassless === 'true'? 's02oIb41jKryy9J6k2XzUAVFKDfgZ2KH' : appConfig.AUTH0_CLIENT_ID,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: appConfig.AUTH0_AUDIENCE,
        scope: appConfig.AUTH0_SCOPE
      }
    })
  }

  return auth0Plugin
}

export default useAuth
